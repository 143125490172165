export const onServiceWorkerUpdateReady = () => {
  
  const answer = window.confirm(
    `Markt 1 ist erweitert worden!  ` +
      `Um die neue Version zu sehen, kann ein Refresh - ein Neuladen des Browsers - notwendig sein.`
  )
  if (answer === true) {
    window.location.reload()
  }
}


